// Maze data
/**
 * 2 - cannot move
 * 3 - can move
 * 0 - floor
 * 0<<left>><<right>><<top>><<bottom>>
 * e.g.
 * "02322" - can move only to right
 * "03223" - can move left and bottom
 * "03232" - can move left and top
 * "02233" - can move top and bottom
 * "02223" - can move only to bottom
 */

export const Mazes = [
    {
        id: 1,
        theme: "grassland",
        qid: "maze1",
        nextPage: "/18dv84s7",
        mazeMap: [
            [
                "02323",
                "03222",
                "02323",
                "03322",
                "03322",
                "03322",
                "03323",
                "03322",
                "03322",
                "03222",
            ], //0
            [
                "02333",
                "03223",
                "02233",
                "02323",
                "03322",
                "03223",
                "02332",
                "03322",
                "03322",
                "03222",
            ], //1
            [
                "02233",
                "02332",
                "03232",
                "02233",
                "02223",
                "02233",
                "02323",
                "03323",
                "03322",
                "03222",
            ], //2
            [
                "02233",
                "02322",
                "03323",
                "03233",
                "02332",
                "03332",
                "03232",
                "02232",
                "02323",
                "03223",
            ], //3
            [
                "02332",
                "03223",
                "02233",
                "02332",
                "03322",
                "03223",
                "02323",
                "03322",
                "03232",
                "02233",
            ], //4
            [
                "02223",
                "02233",
                "02332",
                "03223",
                "02223",
                "02332",
                "03332",
                "03322",
                "03223",
                "02233",
            ], //5
            [
                "02233",
                "02332",
                "03223",
                "03332",
                "03332",
                "03222",
                "02323",
                "03223",
                "02232",
                "02233",
            ], //6
            [
                "02332",
                "03223",
                "02233",
                "02323",
                "03223",
                "02323",
                "03232",
                "02332",
                "03223",
                "02233",
            ], //7
            [
                "02323",
                "03232",
                "02332",
                "03232",
                "02233",
                "02233",
                "02323",
                "03223",
                "02332",
                "03233",
            ], //8
            [
                "02332",
                "03322",
                "03322",
                "03322",
                "03332",
                "03232",
                "02232",
                "02332",
                "03322",
                "03232",
            ], //9
        ],
        player: {
            x: 0,
            y: 0,
            color: "#AB1431",
        },
        goal: {
            x: 9,
            y: 9,
            color: "#FFD700",
        },
        tileDimMin: "40",
        tileDimMax: "50",
        tileType: ["floor", "wall"],
        wallColor: "rgb(0, 0, 0)",
        floorColor: "#B7F0B7",
        pathColor: "#E9E9B4",
        gamePath: [],
        gamePathUndo: [],
        gamePathRedo: [],
    },

    {
        id: 2,
        theme: "grassland",
        qid: "maze2",
        nextPage: "",
        mazeMap: [
            [
                "02323",
                "03223",
                "02323",
                "03322",
                "03322",
                "03323",
                "03322",
                "03222",
                "02323",
                "03323",
                "03223",
                "02322",
                "03323",
                "03223",
                "02323",
                "03223",
                "02323",
                "03322",
                "03322",
                "03223",
            ], //0
            [
                "02233",
                "02233",
                "02332",
                "03322",
                "03233",
                "02332",
                "03223",
                "02333",
                "03233",
                "02233",
                "02333",
                "03332",
                "03232",
                "02233",
                "02233",
                "02233",
                "02233",
                "02323",
                "03322",
                "03232",
            ], //1
            [
                "02233",
                "02333",
                "03222",
                "02323",
                "03232",
                "02223",
                "02233",
                "02233",
                "02223",
                "02233",
                "02232",
                "02323",
                "03223",
                "02332",
                "03232",
                "02233",
                "02232",
                "02332",
                "03322",
                "03223",
            ], //2
            [
                "02233",
                "02332",
                "03223",
                "02233",
                "02322",
                "03233",
                "02233",
                "02233",
                "02233",
                "02332",
                "03322",
                "03232",
                "02332",
                "03223",
                "02223",
                "02332",
                "03322",
                "03322",
                "03322",
                "03233",
            ], //3
            [
                "02332",
                "03222",
                "02233",
                "02332",
                "03322",
                "03233",
                "02332",
                "03232",
                "02333",
                "03222",
                "03323",
                "03323",
                "03223",
                "03233",
                "02333",
                "03322",
                "03323",
                "03323",
                "03222",
                "02233",
            ], //4
            [
                "02323",
                "03322",
                "03332",
                "03223",
                "02223",
                "02233",
                "02322",
                "03322",
                "03233",
                "02323",
                "03232",
                "02233",
                "02232",
                "02233",
                "02323",
                "03223",
                "02233",
                "02332",
                "03322",
                "03232",
            ], //5
            [
                "02333",
                "03223",
                "02322",
                "03332",
                "03233",
                "02332",
                "03322",
                "03223",
                "02333",
                "03232",
                "02223",
                "02332",
                "03223",
                "02233",
                "02233",
                "02233",
                "02233",
                "02322",
                "03322",
                "03223",
            ], //6
            [
                "02233",
                "02332",
                "03322",
                "03223",
                "02233",
                "02323",
                "03223",
                "02233",
                "02332",
                "03322",
                "03233",
                "02223",
                "02233",
                "02332",
                "03232",
                "02233",
                "02332",
                "03322",
                "03322",
                "03233",
            ], //7
            [
                "02233",
                "02322",
                "03223",
                "02233",
                "02233",
                "02232",
                "02233",
                "02332",
                "03223",
                "02323",
                "03232",
                "02233",
                "02332",
                "03322",
                "03223",
                "02233",
                "02322",
                "03322",
                "03323",
                "03232",
            ], //8
            [
                "02332",
                "03223",
                "02233",
                "02233",
                "02332",
                "03223",
                "02332",
                "03223",
                "02233",
                "02332",
                "03322",
                "03233",
                "02323",
                "03223",
                "02233",
                "02233",
                "02323",
                "03223",
                "02332",
                "03222",
            ], //9
            [
                "02323",
                "03332",
                "03232",
                "02332",
                "03223",
                "02233",
                "02323",
                "03233",
                "02332",
                "03223",
                "02322",
                "03232",
                "02233",
                "02332",
                "03232",
                "02332",
                "03232",
                "02333",
                "03322",
                "03223",
            ], //10
            [
                "02233",
                "02323",
                "03322",
                "03223",
                "02233",
                "02332",
                "03232",
                "02232",
                "02323",
                "03332",
                "03322",
                "03322",
                "03233",
                "02323",
                "03322",
                "03322",
                "03223",
                "02332",
                "03222",
                "02233",
            ], //11
            [
                "02232",
                "02233",
                "02223",
                "02333",
                "03232",
                "02323",
                "03322",
                "03223",
                "02233",
                "02223",
                "02223",
                "02322",
                "03233",
                "02233",
                "02322",
                "03323",
                "03232",
                "02323",
                "03223",
                "02233",
            ], //12
            [
                "02323",
                "03232",
                "02333",
                "03232",
                "02323",
                "03332",
                "03222",
                "02233",
                "02233",
                "02233",
                "02332",
                "03223",
                "02232",
                "02333",
                "03223",
                "02332",
                "03322",
                "03232",
                "02332",
                "03233",
            ], //13
            [
                "02332",
                "03223",
                "02232",
                "02323",
                "03232",
                "02323",
                "03223",
                "02233",
                "02233",
                "02333",
                "03222",
                "02332",
                "03223",
                "02233",
                "02332",
                "03223",
                "02323",
                "03322",
                "03322",
                "03232",
            ], //14
            [
                "02323",
                "03232",
                "02323",
                "03332",
                "03222",
                "02233",
                "02233",
                "02233",
                "02233",
                "02333",
                "03322",
                "03223",
                "02233",
                "02232",
                "02323",
                "03232",
                "02332",
                "03223",
                "02323",
                "03223",
            ], //15
            [
                "02333",
                "03223",
                "02332",
                "03223",
                "02323",
                "03232",
                "02332",
                "03232",
                "02233",
                "02233",
                "02323",
                "03232",
                "02233",
                "02323",
                "03232",
                "02322",
                "03223",
                "02233",
                "02232",
                "02233",
            ], //16
            [
                "02232",
                "02233",
                "02323",
                "03232",
                "02332",
                "03322",
                "03322",
                "03322",
                "03232",
                "02233",
                "02332",
                "03223",
                "02233",
                "02332",
                "03323",
                "03223",
                "02233",
                "02332",
                "03322",
                "03233",
            ], //17
            [
                "02323",
                "03232",
                "02332",
                "03322",
                "03223",
                "02322",
                "03323",
                "03322",
                "03223",
                "02332",
                "03222",
                "02233",
                "02332",
                "03223",
                "02232",
                "02233",
                "02333",
                "03323",
                "03222",
                "02233",
            ], //18
            [
                "02332",
                "03322",
                "03322",
                "03322",
                "03332",
                "03322",
                "03232",
                "02322",
                "03332",
                "03322",
                "03322",
                "03332",
                "03222",
                "02332",
                "03322",
                "03232",
                "02232",
                "02332",
                "03322",
                "03232",
            ], //19
        ],
        player: {
            x: 0,
            y: 0,
            color: "#AB1431",
        },
        goal: {
            x: 19,
            y: 19,
            color: "#FFD700",
        },
        tileDimMin: "24",
        tileDimMax: "35",
        tileType: ["floor", "wall"],
        wallColor: "rgb(0, 0, 0)",
        floorColor: "#B7F0B7",
        pathColor: "#E9E9B4",
        gamePath: [],
        gamePathUndo: [],
        gamePathRedo: [],
    },

    {
        id: 3,
        theme: "grassland",
        qid: "maze1",
        //nextPage: "/17dv84s7",
        mazeMap: [
            ["02323", "03323", "03322", "03223", "02223"], //0
            ["02232", "02233", "02323", "03232", "02233"], //1
            ["02323", "03232", "02332", "03322", "03233"], //2
            ["02233", "02323", "03322", "03223", "02233"], //3
            ["02332", "03232", "02323", "03232", "02232"], //4
        ],
        player: {
            x: 0,
            y: 0,
            color: "#AB1431",
        },
        goal: {
            x: 2,
            y: 4,
            color: "#FFD700",
        },
        tileDimMin: "40",
        tileDimMax: "50",
        tileType: ["floor", "wall"],
        wallColor: "rgb(0, 0, 0)",
        floorColor: "#B7F0B7",
        pathColor: "#E9E9B4",
        gamePath: [],
        gamePathUndo: [],
        gamePathRedo: [],
    },
]
