import { keyframes } from "@emotion/react"
import Avatar from "@mui/material/Avatar"
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer"

const wobble = keyframes`
0% { transform: scale(0.85) translate(1px, 1px); }
10% { transform: scale(0.85) translate(-1px, -2px); }
20% { transform: scale(0.85) translate(-3px, 0px); }
30% { transform: scale(0.85) translate(3px, 2px); }
40% { transform: scale(0.85) translate(1px, -1px); }
50% { transform: scale(0.85) translate(-1px, 2px); }
60% { transform: scale(0.85) translate(-3px, 1px); }
70% { transform: scale(0.85) translate(3px, 1px); }
80% { transform: scale(0.85) translate(-1px, -1px); }
90% { transform: scale(0.85) translate(1px, 2px); }
100% { transform: scale(0.85) translate(1px, -2px); }
`

const Sprite = (props) => {
    return (
        <Avatar
            sx={{
                top: props.spriteType.y * props.tileDim + "px",
                left: props.spriteType.x * props.tileDim + "px",
                height: props.tileDim + "px",
                width: props.tileDim + "px",
                borderRadius: props.tileDim + "px",
                transformOrigin: "center",
                transform: "scale(0.85)",
                position: "absolute",
                backgroundColor: props.spriteType.color,
                animation:
                    props.spriteCollide === "collide"
                        ? `${wobble} 0.5s infinite`
                        : "",
                transition:
                    props.spriteCollide === "collide"
                        ? "background-color 0.2s"
                        : "",
            }}
        >
            <SportsSoccerIcon
                sx={{ width: "2em", height: "2em", fontSize: "0.em" }}
            />
        </Avatar>
    )
}
export default Sprite
