/**
 * path variables
 */

const mazeLoginPath = "/lga41d36"
const isometricLoginPath = "/lgb51d36"
const nonogramLoginPath = "/lgc61d36"
const ballSortLoginPath = "/lgd71d36"

const mazePSSPath = "/ssa41d36"
const isometricPSSPath = "/ssb51d36"
const nonogramPSSPath = "/ssc61d36"
const ballSortPSSPath = "/ssd71d36"

const mazePath = "/01dv73s6"
const maze1Path = "/02dv84s7"
const maze2Path = "/03dv84s7"

const ballSortDirectionPath = "/gdp1b2c5"
const ballSortPracticePath = "/pp46mgr3"
const ballSortReadyPath = "/rp0140"
const ballSortExamplePath = "/e16sb37"

const ballSort1Path = "/01e16s2s"
const ballSort2Path = "/0243u6k2"
const ballSort3Path = "/03s688kr"
const ballSort4Path = "/047x0c1x"
const ballSort5Path = "/05dv47c6"

const ifDirectionPath = "/gdh3z4g1"
const ifPracticePath = "/ppgj2d4u"
const ifReadyPath = "/rpha912d"
const ifExamplePath = "/e2h3dg6"

const if1Path = "/01f68p82"
const if2Path = "/020r058n"
const if3Path = "/03a4kg26"
const if4Path = "/048j82p0"
const if5Path = "/0509ca93"

const nonogramDirectionPath = "/gdx628qw"
const nonogramPracticePath = "/ppsd2d42"
const nonogramReadyPath = "/rphj0i0"
const nonogramExamplePath = "/e36h2j7g"

const nonogram1Path = "/01d35l6w"
const nonogram2Path = "/02dl274m"
const nonogram3Path = "/03115kyv"
const nonogram4Path = "/04f2743z"
const nonogram5Path = "/05z327tj"

const difficultySurvey1Path = "/ssg78jf0"
const difficultySurvey2Path = "/ssxh9182"
const difficultySurvey3Path = "/ss87x63b"
const difficultySurvey4Path = "/ssh3kp98"
const difficultySurvey5Path = "/ss15vmx5"
const difficultySurvey6Path = "/ss68bx4n"
const difficultySurvey7Path = "/ssb1e272"
const difficultySurvey8Path = "/ssr64sn0"
const difficultySurvey9Path = "/sson6837"
const difficultySurvey10Path = "/ss689x3w"
const difficultySurvey11Path = "/ssg557vb"
const difficultySurvey12Path = "/sskn4011"
const difficultySurvey13Path = "/ssvp6731"
const difficultySurvey14Path = "/ssue8911"
const difficultySurvey15Path = "/sszu6711"

const finalSurvey1Path = "/t11z9na1"
const finalSurvey2Path = "/t26p83xf"
const finalSurvey3Path = "/t3763w01"

export {
    mazeLoginPath,
    isometricLoginPath,
    nonogramLoginPath,
    ballSortLoginPath,
    mazePSSPath,
    isometricPSSPath,
    nonogramPSSPath,
    ballSortPSSPath,
    ballSortDirectionPath,
    ballSortPracticePath,
    ballSortReadyPath,
    ballSortExamplePath,
    ballSort1Path,
    ballSort2Path,
    ballSort3Path,
    ballSort4Path,
    ballSort5Path,
    finalSurvey1Path,
    ifDirectionPath,
    ifPracticePath,
    ifReadyPath,
    ifExamplePath,
    if1Path,
    if2Path,
    if3Path,
    if4Path,
    if5Path,
    difficultySurvey1Path,
    difficultySurvey2Path,
    difficultySurvey3Path,
    difficultySurvey4Path,
    difficultySurvey5Path,
    difficultySurvey6Path,
    difficultySurvey7Path,
    difficultySurvey8Path,
    difficultySurvey9Path,
    difficultySurvey10Path,
    difficultySurvey11Path,
    difficultySurvey12Path,
    difficultySurvey13Path,
    difficultySurvey14Path,
    difficultySurvey15Path,
    finalSurvey2Path,
    nonogramDirectionPath,
    nonogramPracticePath,
    nonogramReadyPath,
    nonogramExamplePath,
    nonogram1Path,
    nonogram2Path,
    nonogram3Path,
    nonogram4Path,
    nonogram5Path,
    finalSurvey3Path,
    mazePath,
    maze1Path,
    maze2Path,
}
