// Maze data

export const BallSorts = [
    {
        id: 1,
        qid: "ballsort1",
        nextPage: "/ss68bx4n",
        numColors: 4,
        tubeHeight: 4,
        numEmptyTube: 2,
        predefinedColors: [
            [3, 1, 2, 3],
            [0, 3, 1, 0],
            [0, 1, 2, 2],
            [1, 2, 3, 0],
        ],
        colors: [
            "1, 113, 192", // Blue
            "255, 0, 0", // Red
            "6, 176, 80", // Green
            "255, 218, 102", // Yellow
            "112, 47, 161", // Purple
            "255, 138, 217", // Pink
            "116, 254, 255", // Aqua
            "170, 170, 170", // Gray
        ],
    },
    {
        id: 2,
        qid: "ballsort2",
        nextPage: "/ssb1e272",
        numColors: 5,
        tubeHeight: 4,
        numEmptyTube: 2,
        predefinedColors: [
            [2, 0, 3, 0],
            [3, 1, 4, 2],
            [1, 1, 4, 4],
            [2, 3, 2, 3],
            [4, 1, 0, 0],
        ],
        colors: [
            "1, 113, 192", // Blue
            "255, 0, 0", // Red
            "6, 176, 80", // Green
            "255, 218, 102", // Yellow
            "112, 47, 161", // Purple
            "255, 138, 217", // Pink
            "116, 254, 255", // Aqua
            "170, 170, 170", // Gray
        ],
    },
    {
        id: 3,
        qid: "ballsort6",
        nextPage: "/ssr64sn0",
        numColors: 6,
        tubeHeight: 4,
        numEmptyTube: 2,
        predefinedColors: [
            [3, 5, 0, 1],
            [2, 5, 4, 0],
            [3, 0, 3, 5],
            [1, 4, 4, 4],
            [1, 2, 1, 0],
            [2, 2, 3, 5],
        ],
        colors: [
            "1, 113, 192", // Blue
            "255, 0, 0", // Red
            "6, 176, 80", // Green
            "255, 218, 102", // Yellow
            "112, 47, 161", // Purple
            "255, 138, 217", // Pink
            "116, 254, 255", // Aqua
            "170, 170, 170", // Gray
        ],
    },
    {
        id: 4,
        qid: "ballsort7",
        nextPage: "/sson6837",
        numColors: 7,
        tubeHeight: 4,
        numEmptyTube: 2,
        predefinedColors: [
            [4, 5, 5, 3],
            [0, 5, 6, 4],
            [3, 0, 1, 4],
            [3, 1, 6, 4],
            [1, 0, 2, 5],
            [2, 0, 6, 2],
            [2, 6, 1, 3],
        ],
        colors: [
            "1, 113, 192", // Blue
            "255, 0, 0", // Red
            "6, 176, 80", // Green
            "255, 218, 102", // Yellow
            "112, 47, 161", // Purple
            "255, 138, 217", // Pink
            "116, 254, 255", // Aqua
            "170, 170, 170", // Gray
        ],
    },
    {
        id: 5,
        qid: "ballsort8",
        nextPage: "/ss689x3w",
        numColors: 8,
        tubeHeight: 4,
        numEmptyTube: 2,
        predefinedColors: [
            [1, 2, 6, 5],
            [0, 1, 7, 3],
            [4, 2, 2, 6],
            [5, 0, 5, 4],
            [7, 3, 5, 3],
            [6, 7, 1, 6],
            [7, 2, 3, 0],
            [0, 4, 1, 4],
        ],
        colors: [
            "245, 130, 48", // Orange
            "230, 50, 75", // Red
            "0, 130, 200", // Blue
            "255, 250, 130", // Yellow
            "70, 240, 240", // Aqua
            "220, 190, 255", // Purple
            "170, 170, 170", // Gray
            "60, 180, 75", // Green
        ],
    },
]
