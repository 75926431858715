import React, { useState } from "react"
import { Button, Box, AppBar, Toolbar, Typography } from "@mui/material"
import { Form, Input } from "antd"
import axios from "axios"

import "./styles/user.css"
import BasicCard from "../../components/common/basiccard/BasicCard"

const formItemLayout = {
    labelCol: {
        span: 50,
    },
    wrapperCol: {
        span: 50,
    },
}

const baseUrl = "/api/users"

const User = (props) => {
    const [form] = Form.useForm()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [ctyId, setCtyId] = useState()

    let nextPagePath = props.nextPagePath

    let dataValues = {
        ctyID: ctyId,
        startTime: Date().toLocaleString().toString(),
    }

    const handleFormSubmit = async () => {
        setIsSubmitting(true)

        await form
            .validateFields()
            .then((values) => {
                ;(async function () {
                    await axios
                        .post(`${baseUrl}/`, dataValues)
                        .then((response) => {
                            console.log(response)
                            localStorage.setItem("user_id", response.data.id)
                        })

                    window.location.assign(nextPagePath)
                })().finally(() => setIsSubmitting(false))
            })
            .catch((errorInfo) => {
                setIsSubmitting(false)
            })
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 8,
                    gap: 4,
                }}
            >
                <BasicCard
                    content={
                        <>
                            <Typography variant="h5">
                                Please type the numbers of your ERN username in
                                the boxes below
                            </Typography>

                            <Form
                                {...formItemLayout}
                                form={form}
                                layout="vertical"
                            >
                                <Form.Item
                                    name="ctyID"
                                    style={{ padding: "0 0 0 0px" }}
                                    label={
                                        <p style={{ fontSize: "18px" }}>
                                            ERN Username
                                        </p>
                                    }
                                    onChange={(e) => setCtyId(e.target.value)}
                                    rules={[
                                        {
                                            pattern: new RegExp(/^[0-9]+$/),
                                            message:
                                                "The input is not valid CTY research number!",
                                        },
                                        {
                                            required: true,
                                            message:
                                                "Please input your CTY research number!",
                                        },
                                        {
                                            max: 5,
                                            message:
                                                "Your CTY research number should be less than 6 digits!",
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input size="large" />
                                </Form.Item>

                                <Form.Item
                                    name="confirm"
                                    label={
                                        <p style={{ fontSize: "18px" }}>
                                            Retype ERN Username
                                        </p>
                                    }
                                    dependencies={["ctyID"]}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please confirm your CTY research number!",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    getFieldValue("ctyID") ===
                                                        value
                                                ) {
                                                    return Promise.resolve()
                                                }

                                                return Promise.reject(
                                                    new Error(
                                                        "The two CTY research number that you entered do not match!"
                                                    )
                                                )
                                            },
                                        }),
                                    ]}
                                >
                                    <Input size="large" />
                                </Form.Item>
                            </Form>
                        </>
                    }
                />
            </Box>
            <AppBar
                position="fixed"
                color="primary"
                sx={{ top: "auto", bottom: 0 }}
            >
                <Toolbar sx={{ justifyContent: "center" }}>
                    <Button onClick={handleFormSubmit} disabled={isSubmitting}>
                        Submit
                    </Button>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default User
