import React, { useEffect, useState } from "react"
import axios from "axios"

import {
    Box,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableRow,
    AppBar,
    Toolbar,
    IconButton,
} from "@mui/material"

import Avatar from "@mui/material/Avatar"
import { teal } from "@mui/material/colors"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"

import { useWindowDimensions } from "../../util"
import { Mazes } from "./consts/MazeData"
import Sprite from "./Sprite"
import { DoneButton } from "../common/buttons/DoneButton"
import { ResetButton } from "../common/buttons/ResetButton"
import { UndoButton } from "../common/buttons/UndoButton"
import { RedoButton } from "../common/buttons/RedoButton"

//import "./styles/styles.scss"

/**
 *
const Success = styled.div`
    color: ${(props) => props.theme.text};
    transition: opacity 0.2s ease;
    padding: 5px 5px;
    opacity: ${(props) => (props.gameSuccess === "success" ? "1" : "0")};
`
const ShowMessages = styled.div`
    color: ${(props) => props.theme.text};
    transition: opacity 0.2s ease;
    padding: 5px 5px;
`
 */

const baseUrl = "/api/mazes"
const Maze = (props) => {
    /**
     * set tileDim based on windows size
     */
    const maze = Mazes[props.mazeLevel]
    const { height } = useWindowDimensions()
    let tileDim = maze.tileDimMin
    if (height > 700) {
        tileDim = maze.tileDimMax
    }

    let mazeHeight = maze.mazeMap.length * tileDim + "px"
    let mazeWidth = maze.mazeMap[0].length * tileDim + "px"

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [startTimeStr] = useState(Date().toLocaleString().toString())
    const [gameSuccess, setGameSuccess] = useState("")
    const [spriteCollide, setSpriteCollide] = useState("")
    const [userPosition, setUserPosition] = useState([
        maze.player.y,
        maze.player.x,
    ])
    const [gamePathUndo, setGamePathUndo] = useState([])
    const [gamePath, setGamePath] = useState([])

    /**
     * make icon buttons
     */

    const AvatarButtonUp = () => {
        return (
            <IconButton onClick={upButtonClick}>
                <Avatar sx={{ bgcolor: teal[200], width: 56, height: 56 }}>
                    <KeyboardArrowUpIcon sx={{ fontSize: "2em" }} />
                </Avatar>
            </IconButton>
        )
    }
    const AvatarButtonDown = () => {
        return (
            <IconButton onClick={downButtonClick}>
                <Avatar sx={{ bgcolor: teal[200], width: 56, height: 56 }}>
                    <KeyboardArrowDownIcon sx={{ fontSize: "2em" }} />
                </Avatar>
            </IconButton>
        )
    }
    const AvatarButtonLeft = () => {
        return (
            <IconButton onClick={leftButtonClick}>
                <Avatar sx={{ bgcolor: teal[200], width: 56, height: 56 }}>
                    <KeyboardArrowLeftIcon sx={{ fontSize: "2em" }} />
                </Avatar>
            </IconButton>
        )
    }
    const AvatarButtonRight = () => {
        return (
            <IconButton onClick={rightButtonClick}>
                <Avatar sx={{ bgcolor: teal[200], width: 56, height: 56 }}>
                    <KeyboardArrowRightIcon sx={{ fontSize: "2em" }} />
                </Avatar>
            </IconButton>
        )
    }

    /**
     * useEffect hook to show success
     */
    const checkGameStatus = function (playerX, playerY, goalX, goalY) {
        if (playerY === goalY && playerX === goalX) {
            setGameSuccess("success")
        } else {
            setGameSuccess("")
        }
    }

    /**
     * undo button feature
     */
    const handleUndo = () => {
        const newGamePath = gamePath
        const pathLastStep = newGamePath.slice(-1)
        newGamePath.splice(-1, 1)

        const newPositions = newGamePath.slice(-1)
        const newGamePathUndo = gamePathUndo
        setUserPosition([
            (maze.player.y = newPositions[0][0]),
            (maze.player.x = newPositions[0][1]),
        ])
        newGamePathUndo.push([pathLastStep[0][0], pathLastStep[0][1]])

        setGamePath(newGamePath)
        setGamePathUndo(newGamePathUndo)
    }

    /**
     * redo button feature
     */
    const handleRedo = () => {
        const newGamePath = gamePath
        const newGamePathUndo = gamePathUndo

        const lastUndoStep = newGamePathUndo.splice(-1)
        const i = lastUndoStep[0][1]
        const j = lastUndoStep[0][0]
        setUserPosition([(maze.player.y = j), (maze.player.x = i)])
        newGamePath.push([lastUndoStep[0][0], lastUndoStep[0][1]])

        setGamePath(newGamePath)
        setGamePathUndo(newGamePathUndo)
    }

    /**
     * reset the game
     */
    const handleReset = () => {
        setTimeout(() => {
            window.location.reload(false)
        }, 500)
        console.log("page to reload")
    }

    const handleDone = () => {
        setIsSubmitting(true)

        let data = {
            userId: localStorage.getItem("user_id"),
            q_id: maze.qid,
            mazeID: maze.id,

            startTime: startTimeStr,
            endTime: Date().toLocaleString().toString(),

            mazeMap: maze.mazeMap.toString(),
            gamePath: gamePath.toString(),
            gameResult: gameSuccess,
        }

        ;(async function () {
            await axios.post(`${baseUrl}/`, data).then((response) => {
                window.location.assign(maze.nextPage)
            })
        })()
    }

    useEffect(() => {
        checkGameStatus(maze.player.x, maze.player.y, maze.goal.x, maze.goal.y)
    })

    /**
     * functions to move the sprites
     * @param {}
     * @returns
     */
    const moveSprite = (direction) => {
        const [i, j] = userPosition
        const newGamePath = gamePath
        if (
            direction === "ArrowLeft" ||
            direction === "KeyA" ||
            direction === "leftButtonClick"
        ) {
            if (
                maze.player.x === 0 ||
                maze.mazeMap[maze.player.y][maze.player.x].substr(1, 1) === "2"
            ) {
                collide()
                return
            }
            setUserPosition([(maze.player.y = i), (maze.player.x = j - 1)])
            newGamePath.push([i, j - 1])
            setGamePath(newGamePath)
        }
        if (
            direction === "ArrowUp" ||
            direction === "KeyW" ||
            direction === "upButtonClick"
        ) {
            if (
                maze.player.y === 0 ||
                maze.mazeMap[maze.player.y][maze.player.x].substr(3, 1) === "2"
            ) {
                collide()
                return
            }
            setUserPosition([(maze.player.y = i - 1), (maze.player.x = j)])
            newGamePath.push([i - 1, j])
            setGamePath(newGamePath)
        }
        if (
            direction === "ArrowRight" ||
            direction === "KeyD" ||
            direction === "rightButtonClick"
        ) {
            if (
                maze.player.x === maze.mazeMap[maze.player.y].length - 1 ||
                maze.mazeMap[maze.player.y][maze.player.x].substr(2, 1) === "2"
            ) {
                collide()
                return
            }
            setUserPosition([(maze.player.y = i), (maze.player.x = j + 1)])
            newGamePath.push([i, j + 1])
            setGamePath(newGamePath)
        }
        if (
            direction === "ArrowDown" ||
            direction === "KeyS" ||
            direction === "downButtonClick"
        ) {
            if (
                maze.player.y === maze.mazeMap.length - 1 ||
                maze.mazeMap[maze.player.y][maze.player.x].substr(4, 1) === "2"
            ) {
                collide()
                return
            }
            setUserPosition([(maze.player.y = i + 1), (maze.player.x = j)])
            newGamePath.push([i + 1, j])
            setGamePath(newGamePath)
        }
    }

    /**
     * function to move sprites
     * on mouse clicks.
     * @param {}
     * @returns
     */
    const collide = () => {
        setSpriteCollide("collide")
        setTimeout(() => {
            setSpriteCollide("")
        }, 200)
    }
    const handleMove = (e) => {
        e.preventDefault()
        moveSprite(e.code)
    }
    /**
     *
     */
    const upButtonClick = (e) => {
        e.preventDefault()
        moveSprite("upButtonClick")
    }
    const downButtonClick = (e) => {
        e.preventDefault()
        moveSprite("downButtonClick")
    }
    const leftButtonClick = (e) => {
        e.preventDefault()
        moveSprite("leftButtonClick")
    }
    const rightButtonClick = (e) => {
        e.preventDefault()
        moveSprite("rightButtonClick")
    }

    return (
        <>
            <Box
                sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    mt: 9,
                    gap: 4,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                        justifyContent: "center",
                    }}
                >
                    {/**
                     * Disable onClick if completed
                     */}
                    <UndoButton
                        onClick={handleUndo}
                        disabled={isSubmitting || gamePath.length === 0}
                    />

                    {/**
                     * Disable onClick if completed
                     */}
                    <RedoButton
                        onClick={handleRedo}
                        disabled={isSubmitting || gamePathUndo.length === 0}
                    />
                </Box>

                <Box
                    onKeyDown={handleMove}
                    tabIndex={-1}
                    sx={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "row",
                        width: { mazeWidth },
                        height: { mazeHeight },
                        outline: "none !important",
                    }}
                >
                    <TableContainer>
                        <Table sx={{ width: "auto", height: "auto" }}>
                            <TableBody>
                                {maze.mazeMap.map((row, rowIndex) => (
                                    <TableRow
                                        sx={{
                                            height: tileDim + "px",
                                        }}
                                        key={rowIndex}
                                    >
                                        {maze.mazeMap[rowIndex].map(
                                            (col, colIndex) => {
                                                /**
                                                 * color the path
                                                 */
                                                let floorColor = maze.floorColor
                                                for (
                                                    var a = 0;
                                                    a < gamePath.length;
                                                    a++
                                                ) {
                                                    if (
                                                        gamePath[a][0] ===
                                                            rowIndex &&
                                                        gamePath[a][1] ===
                                                            colIndex
                                                    ) {
                                                        floorColor =
                                                            maze.pathColor
                                                    }
                                                }

                                                /**
                                                 * generate walls
                                                 */
                                                let wallLineStyle =
                                                    "2px solid " +
                                                    maze.wallColor
                                                let floorLineStyle =
                                                    "0px solid "
                                                let cellValue =
                                                    maze.mazeMap[rowIndex][
                                                        colIndex
                                                    ].trim()

                                                let wallTopBorder = ""
                                                let wallBottomBorder = ""
                                                let wallLeftBorder = ""
                                                let wallRightBorder = ""

                                                if (
                                                    cellValue.substr(1, 1) ===
                                                    "2"
                                                ) {
                                                    wallLeftBorder =
                                                        wallLineStyle
                                                } else {
                                                    wallLeftBorder =
                                                        floorLineStyle
                                                }

                                                if (
                                                    cellValue.substr(2, 1) ===
                                                    "2"
                                                ) {
                                                    wallRightBorder =
                                                        wallLineStyle
                                                } else {
                                                    wallRightBorder =
                                                        floorLineStyle
                                                }

                                                if (
                                                    cellValue.substr(3, 1) ===
                                                    "2"
                                                ) {
                                                    wallTopBorder =
                                                        wallLineStyle
                                                } else {
                                                    wallTopBorder =
                                                        floorLineStyle
                                                }

                                                if (
                                                    cellValue.substr(4, 1) ===
                                                    "2"
                                                ) {
                                                    wallBottomBorder =
                                                        wallLineStyle
                                                } else {
                                                    wallBottomBorder =
                                                        floorLineStyle
                                                }

                                                return (
                                                    <TableCell
                                                        sx={{
                                                            padding: "0",
                                                            borderBottom:
                                                                wallBottomBorder,

                                                            borderTop:
                                                                wallTopBorder,

                                                            borderRight:
                                                                wallRightBorder,

                                                            borderLeft:
                                                                wallLeftBorder,

                                                            backgroundColor:
                                                                maze.mazeMap[
                                                                    rowIndex
                                                                ][
                                                                    colIndex
                                                                ].substr(
                                                                    0,
                                                                    1
                                                                ) === "0"
                                                                    ? floorColor
                                                                    : maze.wallColor,
                                                            width:
                                                                tileDim + "px",
                                                        }}
                                                        key={colIndex}
                                                    ></TableCell>
                                                )
                                            }
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/**
                     * sprites
                     **/}
                    <Sprite
                        spriteType={maze.player}
                        tileDim={tileDim}
                        spriteCollide={spriteCollide}
                    />
                    <Sprite
                        spriteType={maze.goal}
                        tileDim={tileDim}
                        spriteCollide={""}
                    />

                    {/**
                     * controls
                     **/}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        <AvatarButtonUp />
                        <Box
                            sx={{
                                display: "flex",
                                gap: 4,
                            }}
                        >
                            <AvatarButtonLeft />
                            <AvatarButtonRight />
                        </Box>
                        <AvatarButtonDown />
                    </Box>
                </Box>
            </Box>

            {/**
             * success message
            <Success gameSuccess={gameSuccess}>
                Congratulations!!!. {gamePath.length} steps to get there!
            </Success>
            <ShowMessages>
                Window height is {height} and width is {width}!
            </ShowMessages>
             **/}

            <AppBar
                position="fixed"
                color="primary"
                sx={{ top: "auto", bottom: 0 }}
            >
                <Toolbar sx={{ justifyContent: "center" }}>
                    <Box
                        sx={{
                            display: "flex",
                            gap: 4,
                        }}
                    >
                        <ResetButton
                            onClick={handleReset}
                            disabled={isSubmitting || gamePath.length === 0}
                        />
                        <DoneButton
                            onClick={handleDone}
                            disabled={isSubmitting || gamePath.length === 0}
                        />
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    )
}
export default Maze
