/*
Adapted from:
Title: billowing-cdn-mdkhfx
Type: Source Code
Availability: https://codesandbox.io/s/dvifd
*/

import React, { useState, useEffect } from "react"
import { Box, AppBar, Toolbar } from "@mui/material"
import axios from "axios"

import "./styles/index.css"
import "./styles/style.css"
import _ from "lodash"
import { ballSortReadyPath } from "../../config/redirectPath"
import { UndoButton } from "../common/buttons/UndoButton"
import { RedoButton } from "../common/buttons/RedoButton"
import BasicCard from "../common/basiccard/BasicCard"
import { ResetButton } from "../common/buttons/ResetButton"
import { DoneButton } from "../common/buttons/DoneButton"

const baseImgUrl = "/assets/"
const baseUrl = "/api/ballsorts"
const QID = "ballsort-practice"
const NextPagePath = ballSortReadyPath

const numColors = 3
const tubeHeight = 4
const numEmptyTube = 2

const predefinedColors = [
    [2, 0, 0, 0],
    [1, 0, 1, 2],
    [2, 1, 1, 2],
]

const colors = [
    "1, 113, 192", // Blue
    "255, 0, 0", // Red
    "6, 176, 80", // Green
    "255, 218, 102", // Yellow
    "112, 47, 161", // Purple
    "255, 138, 217", // Pink
    "116, 254, 255", // Aqua
    "170, 170, 170", // Gray
].map((color) => {
    const [r, g, b] = color.split(", ")
    return "rgb(" + [r >> 0, g >> 0, b >> 0].join(", ") + ")"
})

// Image URLs or paths corresponding to each color index
const images = [
    "/icons/icon1.png",
    "/icons/icon2.png",
    "/icons/icon3.png",
    "/icons/icon4.png",
    "/icons/icon5.png",
    "/icons/icon6.png",
    "/icons/icon7.png",
    "/icons/icon8.png",
]

const BallSortPractice = () => {
    const scaleSize = 5 / (numColors + numEmptyTube)

    const [selectedNode, setSelectedNode] = useState(null)
    const [highNode, setHighNode] = useState(null)

    const [startTime, setStartTime] = useState(0)
    const [startTimeStr, setStartTimeStr] = useState("")

    const [initialGameStateStr, setInitialGameStateStr] = useState("")
    const [gameState, setGameState] = useState([])

    const [pastGameState, setPastGameState] = useState([])
    const [allPastGameState, setAllPastGameState] = useState([])

    const [numClicks, setNumClicks] = useState(0)
    const [numUndos, setNumUndos] = useState(0)
    const [numRedos, setNumRedos] = useState(0)
    const [numResets, setNumResets] = useState(0)

    const [isSubmitting, setIsSubmitting] = useState(false)

    const [actions, setActions] = useState([])
    const [actionIndexes, setActionIndexes] = useState([])
    const [actionTimes, setActionTimes] = useState([])

    const [pastCols, setPastCols] = useState([])
    const [pastPrevCols, setPastPrevCols] = useState([])
    const [pastResets, setPastResets] = useState([])

    const [pastUndoCols, setPastUndoCols] = useState([])
    const [pastUndoPrevCols, setPastUndoPrevCols] = useState([])

    const findColNodes = (col) => {
        return _.chain(gameState)
            .filter((node) => node.col === col)
            .sortBy("row")
            .value()
    }

    const hashPosition = ({ row, col }) => `${row}.${col}`

    const onClickTube = (col) => () => {
        setNumClicks(numClicks + 1)

        const colNodes = findColNodes(col)
        const firstNode = colNodes[0]

        const actionIndex = actionIndexes.slice()
        actionIndex.push(col)
        setActionIndexes(actionIndex)

        let timeNow = Date.now() - startTime
        const actionTime = actionTimes.slice()
        actionTime.push(timeNow.toString())
        setActionTimes(actionTime)

        const node = gameState[selectedNode]
        if (node) {
            if (node.col === col) {
                setHighNode(null)

                pastPrevCols.pop()

                const action = actions.slice()
                action.push("back")
                setActions(action)

                const pastGameStates = pastGameState.slice()
                pastGameStates.push(gameStateToString(gameState))
                setPastGameState(pastGameStates)
            } else if (
                !firstNode ||
                (colNodes.length < tubeHeight &&
                    firstNode.colorIndex === node.colorIndex)
            ) {
                const pastGameStates = pastGameState.slice()
                pastGameStates.push(gameStateToString(gameState))
                setPastGameState(pastGameStates)

                const pastCol = pastCols.slice()
                pastCol.push(col)
                setPastCols(pastCol)

                const newState = { ...gameState }
                newState[selectedNode] = {
                    ...node,
                    col,
                    row: firstNode ? firstNode.row - 1 : tubeHeight - 1,
                }
                const newGameState = _.keyBy(newState, hashPosition)
                setGameState(newGameState)

                setHighNode(hashPosition(newState[selectedNode]))
                setTimeout(() => {
                    setHighNode(null)
                }, 200)

                const action = actions.slice()
                action.push("success")
                setActions(action)

                const allPastGameStates = allPastGameState.slice()
                allPastGameStates.push(gameStateToString(newGameState))
                setAllPastGameState(allPastGameStates)
            } else {
                let failureReason
                if (colNodes.length >= tubeHeight) {
                    // If the column is full
                    failureReason = "failed_column_full"
                } else if (
                    firstNode &&
                    firstNode.colorIndex !== node.colorIndex
                ) {
                    // If there's a color mismatch with the top ball in the column
                    failureReason = "failed_color_mismatch"
                } else {
                    // Some other failure reason
                    failureReason = "failed_unknown"
                }

                pastPrevCols.pop()

                setHighNode(null)

                const action = actions.slice()
                action.push(failureReason)
                setActions(action)

                const allPastGameStates = allPastGameState.slice()
                allPastGameStates.push(gameStateToString(gameState))
                setAllPastGameState(allPastGameStates)
            }
            setSelectedNode(null)
        } else if (firstNode) {
            const pastPrevCol = pastPrevCols.slice()
            pastPrevCol.push(col)
            setPastPrevCols(pastPrevCol)

            setSelectedNode(hashPosition(firstNode))
            setHighNode(hashPosition(firstNode))

            const action = actions.slice()
            action.push("selected")
            setActions(action)

            const allPastGameStates = allPastGameState.slice()
            allPastGameStates.push(gameStateToString(gameState))
            setAllPastGameState(allPastGameStates)
        } else {
            pastPrevCols.pop()

            setSelectedNode(null)
            setHighNode(null)

            const action = actions.slice()
            action.push("back")
            setActions(action)

            const allPastGameStates = allPastGameState.slice()
            allPastGameStates.push(gameStateToString(gameState))
            setAllPastGameState(allPastGameStates)
        }
    }

    const handleUndo = () => {
        setNumUndos(numUndos + 1)

        const actionIndex = actionIndexes.slice()
        actionIndex.push("-1")
        setActionIndexes(actionIndex)

        let timeNow = Date.now() - startTime
        const actionTime = actionTimes.slice()
        actionTime.push(timeNow.toString())
        setActionTimes(actionTime)

        const node = gameState[selectedNode]
        if (node) {
            pastPrevCols.pop()

            setHighNode(null)
            setSelectedNode(null)

            const action = actions.slice()
            action.push("undo")
            setActions(action)

            const allPastGameStates = allPastGameState.slice()
            allPastGameStates.push(gameStateToString(gameState))
            setAllPastGameState(allPastGameStates)
        } else {
            if (pastCols.length >= 1) {
                const lastCol = pastCols.pop() // Column where the ball was moved to
                const originalCol = pastPrevCols.pop() // Original column of the ball

                if (lastCol === -1) {
                    const prevStateStr = pastResets.pop() // Only pop if there are elements

                    console.log("undo reset")

                    const prevState = stringToGameState(
                        prevStateStr,
                        hashPosition
                    )
                    setGameState(prevState)

                    const pastUndoCol = pastUndoCols.slice()
                    pastUndoCol.push(-1)
                    setPastUndoCols(pastUndoCol)

                    const pastUndoPrevCol = pastUndoPrevCols.slice()
                    pastUndoPrevCol.push(-1)
                    setPastUndoPrevCols(pastUndoPrevCol)

                    const action = actions.slice()
                    action.push("undo")
                    setActions(action)

                    const allPastGameStates = allPastGameState.slice()
                    allPastGameStates.push(gameStateToString(prevState))
                    setAllPastGameState(allPastGameStates)
                } else {
                    const lastColNodes = findColNodes(lastCol).sort(
                        (a, b) => a.row - b.row
                    )
                    const originalColNodes = findColNodes(originalCol).sort(
                        (a, b) => a.row - b.row
                    )

                    const movedNode = lastColNodes[0] // The top ball in the last affected column
                    const newRow =
                        originalColNodes.length < tubeHeight
                            ? tubeHeight - originalColNodes.length - 1
                            : 0

                    const newState = { ...gameState }
                    newState[hashPosition(movedNode)] = {
                        ...movedNode,
                        col: originalCol,
                        row: newRow,
                    }
                    const newGameState = _.keyBy(newState, hashPosition)
                    setGameState(newGameState)

                    setHighNode(hashPosition(movedNode))
                    setTimeout(() => {
                        setHighNode(null)
                    }, 200)

                    const pastUndoCol = pastUndoCols.slice()
                    pastUndoCol.push(originalCol)
                    setPastUndoCols(pastUndoCol)

                    const pastUndoPrevCol = pastUndoPrevCols.slice()
                    pastUndoPrevCol.push(lastCol)
                    setPastUndoPrevCols(pastUndoPrevCol)

                    const action = actions.slice()
                    action.push("undo")
                    setActions(action)

                    const allPastGameStates = allPastGameState.slice()
                    allPastGameStates.push(gameStateToString(newGameState))
                    setAllPastGameState(allPastGameStates)
                }
            } else {
                console.log("undo failed")

                const action = actions.slice()
                action.push("undo-failed")
                setActions(action)

                const allPastGameStates = allPastGameState.slice()
                allPastGameStates.push(gameStateToString(gameState))
                setAllPastGameState(allPastGameStates)
            }
        }
    }

    const handleRedo = () => {
        setNumRedos(numUndos + 1)

        const actionIndex = actionIndexes.slice()
        actionIndex.push("-1")
        setActionIndexes(actionIndex)

        let timeNow = Date.now() - startTime
        const actionTime = actionTimes.slice()
        actionTime.push(timeNow.toString())
        setActionTimes(actionTime)

        const node = gameState[selectedNode]
        if (node) {
            pastPrevCols.pop()

            setHighNode(null)
            setSelectedNode(null)

            const action = actions.slice()
            action.push("redo")
            setActions(action)

            const allPastGameStates = allPastGameState.slice()
            allPastGameStates.push(gameStateToString(gameState))
            setAllPastGameState(allPastGameStates)
        } else {
            if (pastUndoCols.length >= 1) {
                const lastCol = pastUndoCols.pop() // Column where the ball was moved to
                const originalCol = pastUndoPrevCols.pop() // Original column of the ball

                if (lastCol === -1) {
                    console.log("redo reset")

                    const prevState = stringToGameState(
                        initialGameStateStr,
                        hashPosition
                    )
                    setGameState(prevState)

                    const pastCol = pastCols.slice()
                    pastCol.push(-1)
                    setPastCols(pastCol)

                    const pastPrevCol = pastPrevCols.slice()
                    pastPrevCol.push(-1)
                    setPastPrevCols(pastPrevCol)

                    const pastReset = pastResets.slice()
                    pastReset.push(gameStateToString(gameState))
                    setPastResets(pastReset)

                    const action = actions.slice()
                    action.push("redo")
                    setActions(action)

                    const allPastGameStates = allPastGameState.slice()
                    allPastGameStates.push(gameStateToString(prevState))
                    setAllPastGameState(allPastGameStates)
                } else {
                    const lastColNodes = findColNodes(lastCol).sort(
                        (a, b) => a.row - b.row
                    )
                    const originalColNodes = findColNodes(originalCol).sort(
                        (a, b) => a.row - b.row
                    )

                    const movedNode = lastColNodes[0] // The top ball in the last affected column
                    const newRow =
                        originalColNodes.length < tubeHeight
                            ? tubeHeight - originalColNodes.length - 1
                            : 0

                    const newState = { ...gameState }
                    newState[hashPosition(movedNode)] = {
                        ...movedNode,
                        col: originalCol,
                        row: newRow,
                    }
                    const newGameState = _.keyBy(newState, hashPosition)
                    setGameState(newGameState)

                    const pastCol = pastCols.slice()
                    pastCol.push(originalCol)
                    setPastCols(pastCol)

                    const pastPrevCol = pastPrevCols.slice()
                    pastPrevCol.push(lastCol)
                    setPastPrevCols(pastPrevCol)

                    setHighNode(hashPosition(movedNode))
                    setTimeout(() => {
                        setHighNode(null)
                    }, 200)

                    const action = actions.slice()
                    action.push("redo")
                    setActions(action)

                    const allPastGameStates = allPastGameState.slice()
                    allPastGameStates.push(gameStateToString(newGameState))
                    setAllPastGameState(allPastGameStates)
                }
            } else {
                console.log("redo failed")
            }
        }
    }

    const handleReset = () => {
        setNumResets(numResets + 1)

        const actionIndex = actionIndexes.slice()
        actionIndex.push("-1")
        setActionIndexes(actionIndex)

        let timeNow = Date.now() - startTime
        const actionTime = actionTimes.slice()
        actionTime.push(timeNow.toString())
        setActionTimes(actionTime)

        const pastCol = pastCols.slice()
        pastCol.push(-1)
        setPastCols(pastCol)

        const pastPrevCol = pastPrevCols.slice()
        pastPrevCol.push(-1)
        setPastPrevCols(pastPrevCol)

        const pastReset = pastResets.slice()
        pastReset.push(gameStateToString(gameState))
        setPastResets(pastReset)

        setHighNode(null)
        setSelectedNode(null)

        // Flatten predefined colors into a single array
        const flatColors = predefinedColors.flat()

        const positions = _.chain(numColors)
            .range()
            .map((col) => {
                return _.range(tubeHeight).map((row) => ({ row, col }))
            })
            .flatten()
            .value()

        let state = _.chain(positions)
            .map((position, index) => {
                // Use predefined color index
                return {
                    ...position,
                    colorIndex: flatColors[index],
                }
            })
            .keyBy(hashPosition)
            .value()

        setGameState(state)

        const action = actions.slice()
        action.push("reset")
        setActions(action)

        const allPastGameStates = allPastGameState.slice()
        allPastGameStates.push(gameStateToString(state))
        setAllPastGameState(allPastGameStates)
    }

    const init = () => {
        setStartTime(Date.now())
        setStartTimeStr(Date().toLocaleString())

        setSelectedNode(null)

        // Flatten predefined colors into a single array
        const flatColors = predefinedColors.flat()

        const positions = _.chain(numColors)
            .range()
            .map((col) => {
                return _.range(tubeHeight).map((row) => ({ row, col }))
            })
            .flatten()
            .value()

        let state = _.chain(positions)
            .map((position, index) => {
                // Use predefined color index
                return {
                    ...position,
                    colorIndex: flatColors[index],
                }
            })
            .keyBy(hashPosition)
            .value()

        setGameState(state)

        setInitialGameStateStr(gameStateToString(state))

        const pastGameStates = pastGameState.slice()
        pastGameStates.push(gameStateToString(state))
        setPastGameState(pastGameStates)

        const allPastGameStates = allPastGameState.slice()
        allPastGameStates.push(gameStateToString(state))
        setAllPastGameState(allPastGameStates)
    }

    const handleDone = () => {
        setIsSubmitting(true)

        let gameStateStr = gameStateToString(gameState)

        let data = {
            userId: localStorage.getItem("user_id"),
            q_id: QID,
            numColors: numColors,
            tubeHeight: tubeHeight,
            numEmptyTube: numEmptyTube,

            startTime: startTimeStr.toString(),
            endTime: Date().toLocaleString().toString(),

            numClicks: numClicks,
            numUndos: numUndos,
            numRedos: numRedos,
            numResets: numResets,

            userResponse: gameStateStr,
            allPastGameStates: allPastGameState.toString(),

            actions: actions.toString(),
            actionIndexes: actionIndexes.toString(),
            actionTimes: actionTimes.toString(),
        }

        ;(async function () {
            await axios.post(`${baseUrl}/`, data).then((response) => {
                window.location.assign(NextPagePath)
            })
        })()
    }

    useEffect(init, [])

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 4,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                    }}
                >
                    <UndoButton
                        onClick={handleUndo} // Disable onClick if completed
                        disabled={isSubmitting || pastCols.length === 0}
                    />
                    <RedoButton
                        onClick={handleRedo} // Disable onClick if completed
                        disabled={isSubmitting || pastUndoCols.length === 0}
                    />
                </Box>

                <BasicCard
                    content={
                        <div
                            style={{
                                width:
                                    scaleSize *
                                    ((numColors + numEmptyTube) * 80 + 20),
                                height:
                                    scaleSize * ((tubeHeight + 1) * 60 + 40),
                                position: "relative",
                                margin: "auto",
                                borderRadius: 15,
                            }}
                        >
                            {_.range(numColors + numEmptyTube).map((col) => (
                                <div
                                    onClick={onClickTube(col)}
                                    style={{
                                        borderRadius: "0px 0px 60px 60px",
                                        width: scaleSize * 60,
                                        height: scaleSize * tubeHeight * 60,
                                        position: "absolute",
                                        left: scaleSize * (col * 80 + 15),
                                        top: scaleSize * (1 * 60 + 30),
                                        transition: "all ease 0.2s",
                                        border: `2px #ccc solid`,
                                        borderTop: "none",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: scaleSize * 15,
                                            width: scaleSize * 65,
                                            height: scaleSize * 15,
                                            position: "absolute",
                                            left: scaleSize * -5,
                                            top: scaleSize * -15,
                                            transition: "all ease 0.2s",
                                            border: `2px #ccc solid`,
                                            borderBottom: "0px",
                                        }}
                                    />
                                </div>
                            ))}
                            {_.map(
                                gameState,
                                ({ row, col, colorIndex, locked }) => (
                                    <div
                                        style={{
                                            borderRadius: scaleSize * 50,
                                            background: colors[colorIndex],
                                            width: scaleSize * 50,
                                            height: scaleSize * 50,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            position: "absolute",
                                            left: scaleSize * (col * 80 + 20),
                                            top:
                                                scaleSize *
                                                (hashPosition({ row, col }) ===
                                                highNode
                                                    ? 0 * 60 + 10
                                                    : (row + 1) * 60 + 30),
                                            transition: "all ease 0.2s",
                                            border: `2px solid black`,
                                            pointerEvents: "none",
                                        }}
                                        aria-label={`Ball with color index ${colorIndex}`}
                                    >
                                        <img
                                            src={
                                                baseImgUrl + images[colorIndex]
                                            } // Set the source of the image
                                            alt={`Symbol for color index ${colorIndex}`} // Alternative text for accessibility
                                            style={{
                                                maxWidth: "80%", // Ensure the image does not overflow the ball
                                                maxHeight: "80%",
                                            }}
                                        />
                                    </div>
                                )
                            )}
                        </div>
                    }
                />
            </Box>

            <AppBar
                position="fixed"
                color="primary"
                sx={{ top: "auto", bottom: 0 }}
            >
                <Toolbar sx={{ justifyContent: "center" }}>
                    <Box
                        sx={{
                            display: "flex",
                            gap: 4,
                        }}
                    >
                        <ResetButton
                            onClick={handleReset}
                            disabled={
                                isSubmitting ||
                                isInitialState(
                                    gameStateToString(gameState),
                                    initialGameStateStr
                                )
                            }
                        />
                        <DoneButton
                            onClick={handleDone}
                            disabled={isSubmitting}
                        />
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    )
}

function gameStateToString(gameState) {
    let result = []

    for (let position in gameState) {
        let node = gameState[position]
        result.push(
            `[row:${node.row}, col:${node.col}, color:${node.colorIndex}]`
        )
    }

    let gameStateStr = "{" + result.join(";") + "}"

    return gameStateStr
}

function stringToGameState(gameStateStr, hashPosition) {
    // Remove the braces and split the string into node components
    const nodes = gameStateStr
        .slice(1, -1)
        .split(";")
        .map((str) => str.trim())

    // Initialize an object to collect nodes by their column indices
    const columns = {}

    // Process each node string to extract row, column, and color index
    nodes.forEach((node) => {
        // Example node: "[row:2, col:1, color:2]"
        const details = node
            .slice(1, -1)
            .split(", ")
            .reduce((acc, detail) => {
                const [key, value] = detail.split(":")
                acc[key] = parseInt(value)
                return acc
            }, {})

        // Ensure each column is initialized as an array
        if (!columns[details.col]) {
            columns[details.col] = []
        }

        // Place the node details in the array at the correct row position
        columns[details.col][details.row] = {
            row: details.row,
            col: details.col,
            colorIndex: details.color,
        }
    })

    const state = {}

    // Fill the state object, ensuring each tube is filled from bottom to top
    Object.keys(columns).forEach((colIndex) => {
        const col = columns[colIndex]
        // Reverse the column to ensure the bottom-up order
        col.reverse().forEach((node) => {
            const posHash = hashPosition(node)
            state[posHash] = node
        })
    })

    return state
}

function stringToColors(gameStateStr) {
    // First, remove the braces and split the string into node components
    const nodes = gameStateStr
        .slice(1, -1)
        .split(";")
        .map((str) => str.trim())

    // Initialize an object to collect colors by their column indices
    const columns = {}

    // Process each node string to extract row, column, and color index
    nodes.forEach((node) => {
        // Example node: "[row:2, col:1, color:2]"
        const details = node
            .slice(1, -1)
            .split(", ")
            .reduce((acc, detail) => {
                const [key, value] = detail.split(":")
                acc[key] = parseInt(value)
                return acc
            }, {})

        // Ensure each column is initialized as an array
        if (!columns[details.col]) {
            columns[details.col] = new Array() // Create an empty array for each column
        }

        // Place the color index at the correct row position within its column
        columns[details.col][details.row] = details.color
    })

    // To handle missing columns and ensure columns with gaps have undefined entries
    const maxColIndex = Math.max(...Object.keys(columns).map(Number)) // Find the highest column index to account for all columns
    const sortedColors = []

    for (let i = 0; i <= maxColIndex; i++) {
        if (columns[i]) {
            // Fill undefined entries in columns with existing data
            sortedColors.push(
                columns[i]
                    .map((color) => (color !== undefined ? color : undefined))
                    .filter((color) => color !== undefined)
            )
        } else {
            // If no data for the column, add an empty array
            sortedColors.push([])
        }
    }

    return sortedColors
}

function isInitialState(currGameStateStr, initialGameStateStr) {
    return areNestedArraysEqual(
        stringToColors(currGameStateStr),
        stringToColors(initialGameStateStr)
    )
}

function areNestedArraysEqual(arr1, arr2) {
    // First check if both arrays have the same length
    if (arr1.length !== arr2.length) {
        return false
    }

    // Iterate through each element of the arrays
    for (let i = 0; i < arr1.length; i++) {
        // Check if corresponding elements are both arrays
        if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
            // Check if these nested arrays have the same length
            if (arr1[i].length !== arr2[i].length) {
                return false
            }
            // Check each element in the nested arrays
            for (let j = 0; j < arr1[i].length; j++) {
                if (arr1[i][j] !== arr2[i][j]) {
                    return false // Elements at the same position are not equal
                }
            }
        } else {
            // If the corresponding elements are not both arrays or differ
            return false
        }
    }

    // If all checks pass, the arrays are considered equal
    return true
}

export default BallSortPractice
