import React, { useState } from "react"
import { Button, AppBar, Toolbar } from "@mui/material"
import { Form, Input, Select, Radio, Space } from "antd"
import axios from "axios"

import "./styles/index.css"

const baseUrl = "/api/prestudysurveys"
const PreStudySurvey = (props) => {
    const [form] = Form.useForm()
    const [isSubmitting, setIsSubmitting] = useState(false)
    /**
    const [buttonBackgroundColor, setButtonBackgroundColor] =
        useState("#9FC5E8")
    */
    const [deviceValue, setDeviceValue] = useState("")
    const [controlValue, setControlValue] = useState("")
    const [incogValue, setIncogValue] = useState("")
    const [otherDevice, setOtherDevice] = useState("") // State for other device input
    const [otherControl, setOtherControl] = useState("") // State for other method input
    let nextPagePath = props.nextPagePath

    const handleOtherDeviceChange = (e) => {
        setOtherDevice(e.target.value)
        console.log(otherDevice)
    }

    const handleOtherControlChange = (e) => {
        setOtherControl(e.target.value)
        console.log(otherControl)
    }

    const onChangeDevice = (e) => {
        console.log("device radio checked", e.target.value)
        setDeviceValue(e.target.value)
    }

    const onChangeIncog = (e) => {
        console.log("incog radio checked", e.target.value)
        setIncogValue(e.target.value)
    }

    const onChangeControl = (e) => {
        console.log("control radio checked", e.target.value)
        setControlValue(e.target.value)
    }

    const handleFormSubmit = async () => {
        try {
            //const values = await form.validateFields()
            setIsSubmitting(true)
            //setButtonBackgroundColor("#555")

            if (deviceValue === "4") {
                console.log("device is a phone")
                nextPagePath = "/error/phone"
                window.location.assign(nextPagePath)
            } else if (incogValue === "1") {
                // Use strict equality for comparison
                console.log("browser in incognito mode")
                nextPagePath = "/error/incognito/"
                window.location.assign(nextPagePath)
            } else {
                // Ensure you submit these values somewhere
                await form
                    .validateFields()
                    .then((submitValues) => {
                        setIsSubmitting(true)
                        submitValues.userId = localStorage.getItem("user_id")
                        submitValues.otherDevice = otherDevice
                        submitValues.otherControl = otherControl
                        ;(async function () {
                            await axios
                                .post(`${baseUrl}/`, submitValues)
                                .then((response) => {
                                    window.location.assign(nextPagePath)
                                })
                        })()
                    })
                    .catch((errorInfo) => {})
            }
            //window.location.assign(nextPagePath)
        } catch (error) {
            console.error("Form validation failed:", error)
            // Handle the error appropriately
        }
    }

    return (
        <div className="survey-page">
            <Form form={form} layout="vertical">
                <Form.Item
                    name="grade"
                    label={
                        <p style={{ fontSize: "26px" }}>
                            What grade are you currently in?
                        </p>
                    }
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    style={{
                        fontSize: "26px",
                        alignContent: "start",
                        marginTop: 50,
                    }}
                >
                    <Select>
                        <Select.Option value="1">1st grade</Select.Option>
                        <Select.Option value="2">2nd grade</Select.Option>
                        <Select.Option value="3">3rd grade</Select.Option>
                        <Select.Option value="4">4th grade</Select.Option>
                        <Select.Option value="5">5th grade</Select.Option>
                        <Select.Option value="6">6th grade</Select.Option>
                        <Select.Option value="7">7th grade</Select.Option>
                        <Select.Option value="8">8th grade</Select.Option>
                        <Select.Option value="9">9th grade</Select.Option>
                        <Select.Option value="10">10th grade</Select.Option>
                        <Select.Option value="11">11th grade</Select.Option>
                        <Select.Option value="12">12th grade</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="device"
                    label={
                        <p style={{ fontSize: "25px", marginBottom: "5px" }}>
                            What device are you using to view this?
                        </p>
                    }
                    style={{
                        fontSize: "26px",
                        alignContent: "start",
                        alignItems: "start",
                    }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Radio.Group
                        style={{
                            fontSize: "26px",
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "left",
                        }}
                        onChange={onChangeDevice}
                        value={deviceValue}
                    >
                        <Space
                            direction="vertical"
                            align="start"
                            style={{ marginLeft: 30 }}
                        >
                            <Radio style={{ fontSize: "22px" }} value="1">
                                Desktop Computer
                            </Radio>
                            <Radio style={{ fontSize: "22px" }} value="2">
                                Laptop Computer
                            </Radio>
                            <Radio style={{ fontSize: "22px" }} value="3">
                                Tablet Device
                            </Radio>
                            <Radio style={{ fontSize: "22px" }} value="4">
                                Phone
                            </Radio>
                            <Radio style={{ fontSize: "22px" }} value="5">
                                Other (please specify)
                                {deviceValue === "5" ? (
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        value={otherDevice}
                                        onChange={handleOtherDeviceChange}
                                    />
                                ) : null}
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="control"
                    label={
                        <p style={{ fontSize: "25px", marginBottom: "5px" }}>
                            What method are you using for movement?
                        </p>
                    }
                    style={{
                        fontSize: "26px",
                        alignContent: "start",
                        marginTop: "20px",
                    }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Radio.Group
                        style={{
                            fontSize: "26px",
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "left",
                        }}
                        onChange={onChangeControl}
                        value={controlValue}
                    >
                        <Space
                            direction="vertical"
                            align="start"
                            style={{ marginLeft: 30 }}
                        >
                            <Radio style={{ fontSize: "22px" }} value="1">
                                Mouse
                            </Radio>
                            <Radio style={{ fontSize: "22px" }} value="2">
                                Trackpad
                            </Radio>
                            <Radio style={{ fontSize: "22px" }} value="3">
                                Touchscreen with Finger
                            </Radio>
                            <Radio style={{ fontSize: "22px" }} value="4">
                                Touchscreen with Stylus/e-Pencil (Apple pencil,
                                etc.)
                            </Radio>
                            <Radio style={{ fontSize: "22px" }} value="5">
                                Other (please specify)
                                {controlValue === "5" ? (
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        value={otherControl}
                                        onChange={handleOtherControlChange}
                                    />
                                ) : null}
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="incog"
                    label={
                        <p style={{ fontSize: "25px", marginBottom: "5px" }}>
                            Is the browser window you are currently using to
                            view this page in incognito mode?
                        </p>
                    }
                    style={{
                        fontSize: "26px",
                        alignContent: "start",
                        alignItems: "start",
                    }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Radio.Group
                        style={{
                            fontSize: "26px",
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "left",
                        }}
                        onChange={onChangeIncog}
                        value={incogValue}
                    >
                        <Space
                            direction="vertical"
                            align="start"
                            style={{ marginLeft: 30 }}
                        >
                            <Radio style={{ fontSize: "22px" }} value="1">
                                Yes
                            </Radio>
                            <Radio style={{ fontSize: "22px" }} value="2">
                                No
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </Form>
            <AppBar
                position="fixed"
                color="primary"
                sx={{ top: "auto", bottom: 0 }}
            >
                <Toolbar sx={{ justifyContent: "center" }}>
                    <Button onClick={handleFormSubmit} disabled={isSubmitting}>
                        Submit
                    </Button>
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default PreStudySurvey
