import React from "react"
import { Box, AppBar, Toolbar } from "@mui/material"

import "./styles/direction.css"

const NoIncognito = (props) => {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "80vh",
                    gap: 4,
                }}
            >
                <div className="direction-page-content">
                    <h2 className="direction-page-left-align">
                        Currently, our website is{" "}
                        <b>not compatible with incognito mode</b> in the
                        browser. We kindly request that you switch to a standard
                        browsing mode and restart using the link below. Thank
                        you for your understanding and cooperation.
                    </h2>
                </div>
            </Box>
            <AppBar
                position="fixed"
                color="primary"
                sx={{ top: "auto", bottom: 0 }}
            >
                <Toolbar sx={{ justifyContent: "center" }}></Toolbar>
            </AppBar>
        </>
    )
}

export default NoIncognito
