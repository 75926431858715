import React from "react"
import Grid from "@mui/material/Grid"

const GridWrapper = ({ children }) => {
    return (
        <Grid
            item
            xs={12}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {children}
        </Grid>
    )
}

export default GridWrapper
